import React, { useState, useEffect, useRef } from 'react'
import { StaticQuery, graphql } from "gatsby"
import styled from "@emotion/styled"
import Img from 'gatsby-image'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faChevronLeft, faChevronRight} from'@fortawesome/free-solid-svg-icons'
import { RedFancyButton } from '../Buttons'
import diamond_right from '../../images/diamond-right.png'

const ReasonsContainer = styled.div`
margin-bottom: 30px;

@media(min-width: 992px){
    display: grid;
    grid-template-columns: 50% 50%;
}
`
const Column1 = styled.div`

`
const FourCardsDesktopContainer = styled.div`
display: none;
@media(min-width: 992px){
    display: flex;
    flex-direction: row;
}
`
const FourCardsColumn1 = styled.div`
margin: -20px 30px 0 75px;

@media(min-width: 1367px){
    margin: -50px 30px 0 20%;
}
@media(min-width: 1600px){
    margin: -120px 30px 0 25%;
}
`
const FourCardsColumn2 = styled.div`
margin: 20px 15px 0 0;

@media(min-width: 1600px){
    margin: -80px 15px 0 0;
}
`
const SliderMobile = styled(Slider)`
margin: -50px 30px 10px 30px;

.slick-next{
    right: -30px;
}
.slick-prev{
    left: -30px;
}
.slick-next, .slick-prev{
    color: #09182d;
    width: 20px;
    height: 20px;
}
.numberDots{
    list-style: none;
    text-align: center;
    padding-left: 0;
}
.numberDots li{
    display: inline-block;
    width: 28px;
    height: 28px;
    line-height: 28px;
    margin: 0 20px;
}
.numberDots li a{
    color: #999;
}
.numberDots li.slick-active{
    background-color: #0e1f38;
}
.numberDots li.slick-active a{
    color: #fff;
}
@media(min-width: 576px){
    max-width: 540px;
    margin: -50px auto 0 auto;
}
@media(min-width: 992px){
    display: none;
}
`
const Column2 = styled.div`
padding: 0 15px;

@media(min-width: 576px){
    max-width: 540px;
    margin: 0 auto;
}
@media(min-width: 992px){
    max-width: 100%;
    padding: 0 70px;
    flex: 0 0 50%;
    max-width: 650px;
}
`
const Subheading = styled.span`
color: #b61f2a;
    font-weight: 700;
    padding-top: 10px;
    display: block;
`
const HeaderSection = styled.div`
position: relative;
margin-bottom: 35px;

@media(min-width: 992px){
    margin: 80px 0;
}
`
const DiamondRight = styled.img`
bottom: -33px;
    width: 70% !important;
    overflow: hidden;
    left: 0;
    position: absolute !important;

    @media(min-width: 992px){
        width: 501px !important;
        left: -58%;
        bottom: -44px;
    }
`
const FontAwesomeIconStyled = styled(FontAwesomeIcon)`
display: block;  
width: 44px !important; 
height: 36px;
color:  rgba(255, 255, 255, 0.7);


:hover{
    color: #fff;
}

`
const ParagraphContainer = styled.div`
margin-top: 50px;
line-height: 24px;
`
const Card = styled.div`
position: relative;
padding: 30px;
background: #fff;
border: 4px solid #fff;
    box-shadow: 0 8px 20px #d2d2d2;
    margin-bottom: 30px;
    text-align: center;
transition: border .5s;
    :hover{
        border: 4px solid #b61f2a
    }
`
const Number = styled.span`
position: absolute;
top: 10px;
    right: 18px;
    font-size: 30px;
    color: #ccc;
    font-family: "Playfair Display";
    font-weight: 400;
`
const CardP = styled.p`
color: #000;
font-weight: 600;
font-size: 14px;
line-height: 24px;
`
const StyledH4 = styled.h4`
max-width: 348px;
margin-bottom: 31px;
color: #0e1f38;
    font-size: 45px;
    letter-spacing: 2px;
    font-family: "Playfair Display";
    font-weight: 500;
    line-height: 1.2;

    @media(min-width: 992px){
        letter-spacing: 4px;
        margin-bottom: .5rem;
    }
`
const RedFancyButtonContainer = styled.div`
@media(min-width: 992px){
    margin: 40px 0 1rem 0;
}
`
const createLocalLink = url => {
    if (`#` === url) {
      return null
    }
    return url ? url.replace('https://backend-gatsby.mmklawfirm.com', ``) : url
  }
function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <FontAwesomeIconStyled
        icon={faChevronRight}
        className={className}
        onClick={onClick}
      />
    );
  }
  
  function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <FontAwesomeIconStyled
      icon={faChevronLeft}
        className={className}
        onClick={onClick}
      />
    );
  }

  const HomeReasons = () =>{
    const [nav1, setNav1] = useState();
    const slider1 = useRef(null);

    useEffect(() => {
        setNav1(slider1.current);
      }, []);
      const settings = {
        customPaging: function(i) {
            return (
              <a>
                0{(i+1)}
              </a>
            );
          },
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows:true,
        dots: true,
        dotsClass: 'numberDots',
        nextArrow: <SampleNextArrow />,
prevArrow: <SamplePrevArrow />,
        responsive: [

            {
                breakpoint:800,
                settings: {
                    customPaging: function(i) {
                        return (
                          <a>
                            0{(i+1)}
                          </a>
                        );
                      },
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    arrows:true,
                    dots: true,
                    dotsClass: 'numberDots',
                    nextArrow: <SampleNextArrow />,
prevArrow: <SamplePrevArrow />,
                }
            },
            {
                breakpoint: 480,
                settings: {
                    customPaging: function(i) {
                        return (
                          <a>
                            0{(i+1)}
                          </a>
                        );
                      },
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    arrows:true,
                    dots: true,
                    dotsClass: 'numberDots',
                    nextArrow: <SampleNextArrow />,
prevArrow: <SamplePrevArrow />,
                }
            }
            ]
      }
      return(
        <StaticQuery
              query={graphql`
              {
                wpgraphql {
                    page(id: "cGFnZToyNg==") {
                        Homepage {
                            reasonsH4
              reasonsTagline
              reasonsText
              reasonsButtonText
              reasonsButtonLink {
                url
              }
                        }
                    }
                }
                mainImage: file(relativePath: {eq: "reason_to_choose_bg.png"}) {
                    childImageSharp {
                      fluid(maxWidth: 991, quality: 100) {
                          ...GatsbyImageSharpFluid_withWebp_noBase64
                      }
                    }
                  }
                  folderIcon: file(relativePath: {eq: "c_i1.png"}) {
                    childImageSharp {
                      fixed(width: 74, height: 65) {
                          ...GatsbyImageSharpFixed_withWebp_noBase64
                      }
                    }
                  }
                  reputationIcon: file(relativePath: {eq: "c_i2.png"}) {
                    childImageSharp {
                      fixed(width: 74, height: 65) {
                          ...GatsbyImageSharpFixed_withWebp_noBase64
                      }
                    }
                  }
                  playbookIcon: file(relativePath: {eq: "c_i3.png"}) {
                    childImageSharp {
                      fixed(width: 74, height: 65) {
                          ...GatsbyImageSharpFixed_withWebp_noBase64
                      }
                    }
                  }
                  ribbonIcon: file(relativePath: {eq: "c_i4.png"}) {
                    childImageSharp {
                      fixed(width: 74, height: 65) {
                          ...GatsbyImageSharpFixed_withWebp_noBase64
                      }
                    }
                  }
                  
              }
              `
            }
              render={props => (
<ReasonsContainer>
    <Column1>
    <Img fluid={props.mainImage.childImageSharp.fluid} loading="lazy"/>
    <FourCardsDesktopContainer>
<FourCardsColumn1>
<Card>
                <Img fixed={props.folderIcon.childImageSharp.fixed} loading="lazy"/>
                <CardP>
                    We have received hundreds of millions of dollars for our clients
                </CardP>
                </Card>
                <Card>
                <Img fixed={props.ribbonIcon.childImageSharp.fixed} loading="lazy"/>
                <CardP>
                    We represent both employees and employers &amp; know how to win your case
                </CardP>
                </Card>
</FourCardsColumn1>
<FourCardsColumn2>
<Card>
                <Img fixed={props.reputationIcon.childImageSharp.fixed} loading="lazy"/>
                <CardP>
                    Our reputation among judges, our fellow lawyers, and clients is impeccable
                </CardP>
                </Card>
                <Card>
                <Img fixed={props.playbookIcon.childImageSharp.fixed} loading="lazy"/>
                <CardP>
                    Our law firm has more than 50 years of collective experience
                </CardP>
                </Card>
</FourCardsColumn2>
    </FourCardsDesktopContainer>
    <SliderMobile ref={slider1} {...settings}>
                <Card>
                <Number>
                    01
                </Number>
                <Img fixed={props.folderIcon.childImageSharp.fixed} loading="lazy"/>
                <CardP>
                    We have received hundreds of millions of dollars for our clients
                </CardP>
                </Card>
                <Card>
                <Number>
                    02
                </Number>
                <Img fixed={props.reputationIcon.childImageSharp.fixed} loading="lazy"/>
                <CardP>
                    Our reputation among judges, our fellow lawyers, and clients is impeccable
                </CardP>
                </Card>
                <Card>
                <Number>
                    03
                </Number>
                <Img fixed={props.ribbonIcon.childImageSharp.fixed} loading="lazy"/>
                <CardP>
                    We represent both employees and employers &amp; know how to win your case
                </CardP>
                </Card>
                <Card>
                <Number>
                    04
                </Number>
                <Img fixed={props.playbookIcon.childImageSharp.fixed} loading="lazy"/>
                <CardP>
                    Our law firm has more than 50 years of collective experience
                </CardP>
                </Card>
    </SliderMobile>
    </Column1>
    <Column2>
    <HeaderSection>
    <StyledH4>
        {props.wpgraphql.page.Homepage.reasonsH4}
    </StyledH4>
    <Subheading>
        {props.wpgraphql.page.Homepage.reasonsTagline}
    </Subheading>
    <DiamondRight src={diamond_right} loading="lazy"/>
    </HeaderSection>
    <ParagraphContainer dangerouslySetInnerHTML={{__html: props.wpgraphql.page.Homepage.reasonsText}}/>
   <RedFancyButtonContainer>
    <RedFancyButton to={createLocalLink(props.wpgraphql.page.Homepage.reasonsButtonLink.url)}>
                {props.wpgraphql.page.Homepage.reasonsButtonText}
    </RedFancyButton>
    </RedFancyButtonContainer>
    </Column2>
</ReasonsContainer>
              )
}
/>
)
  }
  export default HomeReasons